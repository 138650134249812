<template lang='pug'>
  #live.view
    .buttons
      basic-button( v-if='host && !local' @click.native='open' ) Preview
      basic-button( v-if='host && local && !p2p' @click.native='create' ) Go Live
      basic-button( v-if='!host' @click.native='join' ) Join
      basic-button( v-if='host && local && p2p' @click.native='quit' ) Quit
    video#local-stream( ref='local' muted="" autoplay="" playsinline="" )
    video#remote-stream( ref='remote' autoplay="" playsinline="" )
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'
  import { Sociable } from '@/mixins/firebase'
  import BasicButton from '@/components/buttons/basic'


  export default {
    name: 'live.view',

    mixins: [ Viewable ],

    components: {
      BasicButton
    },

    props: {
      // session: { type: Object, default: () => ({}), required: true }
    },

    data() {
      return {
        idol: null,
        audience: [],
        show: { messages: false },
        sound: { on: true },
        sound_on: true,
        local: null,
        remote: null,
        p2p: null,
        configuration: {
          iceServers: [
            {
              urls: [
                'stun:stun1.l.google.com:19302',
                'stun:stun2.l.google.com:19302',
              ],
            },
          ],

          iceCandidatePoolSize: 10
        },

        messages: []
      }
    },

    firestore() {
      return {
        // messages: db.collection( 'sessions' ).doc( this.session.id ).collection( 'sessions' )
        idol: {
          ref: db.collection( 'profiles' ).doc( this.$route.params.id ),
          resolve: doc => {
            // console.log( this.host )
            // console.log( this.profile.id, this.$route.params.id )
            // if ( this.host ) this.open()
          }
        },
      }
    },

    computed: {
      room() {
        return { id: `live-broadcast-test->${ this.$route.params.id }` }
      },

      host() {
        return this.profile.id == this.$route.params.id 
      }
    },

    watch: {
      // host( curr, prev ) {
      //   if ( curr ) this.open()
      // },

      sound_on( curr, prev ) {
        // console.log(changed)
        if ( this.local.getAudioTracks().length > 0 ) this.local.getAudioTracks()[0].enabled = curr
      }
    },

    methods: {
      close() {
        if ( confirm('This will end the session permanantly.  Are you sure?') )
          db.collection( 'sessions' ).doc( this.session.id ).delete()
      },

      quit() {
        
      },

      async open() {
        this.local = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        this.$refs.local.srcObject = this.local
        this.remote = new MediaStream()
        // if ( this.host ) this.create()
        // else this.join()
      },

      async create() {
        const roomRef = await db.collection('rooms').doc( this.room.id )
        this.p2p = new RTCPeerConnection( this.configuration )

        this.registerPeerConnectionListeners()

        this.local.getTracks().forEach( track => {
          console.log('track obtained', track)
          this.p2p.addTrack( track, this.local )
        })

        // collecting ICE candidates
        const callerCandidatesCollection = roomRef.collection( 'callerCandidates' )

        this.p2p.addEventListener( 'icecandidate', event => {
          if ( event.candidate ) callerCandidatesCollection.add( event.candidate.toJSON() )
        })

        // creating a room
        const offer = await this.p2p.createOffer()
        await this.p2p.setLocalDescription( offer )

        const roomWithOffer = {
          'offer': {
            type: offer.type,
            sdp: offer.sdp,
          },
        }
        await roomRef.set(roomWithOffer)
      
        this.p2p.addEventListener('track', event => {
          event.streams[0].getTracks().forEach( track => {
            this.remote.addTrack( track )
          })
        })

        // Listening for remote session description
        roomRef.onSnapshot( async snapshot => {
          const data = snapshot.data()
          if ( !this.p2p.currentRemoteDescription && data && data.answer ) {
            const rtcSessionDescription = new RTCSessionDescription(data.answer)
            await this.p2p.setRemoteDescription(rtcSessionDescription)
          }
        })

        // Listen for remote ICE candidates
        roomRef.collection('calleeCandidates').onSnapshot( snapshot => {
          snapshot.docChanges().forEach( async change => {
            if (change.type === 'added') {
              let data = change.doc.data()
              await this.p2p.addIceCandidate(new RTCIceCandidate(data))
            }
          })
        })
      },

      async join() {
        const roomRef = db.collection('rooms').doc(`${this.room.id}`)
        const roomSnapshot = await roomRef.get()

        if ( roomSnapshot.exists ) {
          this.p2p = new RTCPeerConnection( this.configuration )
          this.registerPeerConnectionListeners()
          // this.local.getTracks().forEach( track => this.p2p.addTrack( track, this.local ) )

          // collecting ICE candidates
          const calleeCandidatesCollection = roomRef.collection( 'calleeCandidates' )
          this.p2p.addEventListener('icecandidate', event => {
            if ( event.candidate ) calleeCandidatesCollection.add( event.candidate.toJSON() )
          })


          this.remote = new MediaStream()
          this.$refs.remote.srcObject = this.remote
          this.p2p.addEventListener( 'track', event => {
            event.streams[0].getTracks().forEach( track => {
              this.remote.addTrack( track )
            })
          })

          // creating SDP 
          const offer = roomSnapshot.data().offer
          await this.p2p.setRemoteDescription( new RTCSessionDescription(offer) )
          const answer = await this.p2p.createAnswer()
          await this.p2p.setLocalDescription( answer )

          const roomWithAnswer = {
            answer: {
              type: answer.type,
              sdp: answer.sdp,
            }
          }
          await roomRef.update(roomWithAnswer)

          // Listening for remote ICE candidates
          roomRef.collection('callerCandidates').onSnapshot( snapshot => {
            snapshot.docChanges().forEach( async change => {
              if ( change.type === 'added' ) {
                let data = change.doc.data()
                await this.p2p.addIceCandidate( new RTCIceCandidate( data ) )
              }
            })
          })
        }
      },

      registerPeerConnectionListeners() {
        this.p2p.addEventListener('icegatheringstatechange', () => {
          console.log( `ICE gathering state changed: ${this.p2p.iceGatheringState}` )
        })

        this.p2p.addEventListener('connectionstatechange', () => {
          console.log( `Connection state change: ${this.p2p.connectionState}` )
        })

        this.p2p.addEventListener('signalingstatechange', () => {
          console.log( `Signaling state change: ${this.p2p.signalingState}` )
        })

        this.p2p.addEventListener('iceconnectionstatechange ', () => {
          console.log( `ICE connection state change: ${this.p2p.iceConnectionState}` )
        })
      }
    },

    created() {
      // this.open()
    }
  }
</script>

<style scoped lang='stylus'>

</style>