<template lang='pug'>
  button.button.basic.component(:type='type' :title='title')
    slot
</template>

<script>
  export default {
    name: 'button.combo.component',

    props: {
      type: { type: String, default: 'button' },
      title: String
    }
  }
</script>

<style scoped lang='stylus'>
  .button.basic
    padding 12px 24px
    text-shadow inherit
    border 1px solid rgba( 88 88 88 .1 )
    box-shadow 0 0 1px 1px rgba( 255 255 255 1 ) inset
    align-items center
    transition transform 320ms
    background 0
    border-radius 3px
    &:hover
      box-shadow 0 0 16px 0px rgba( 0 0 0 .1 )
      border-color rgba( 88 88 88 .2 )
    &:active
      transform scale(.88)
    &:focus
      outline 0

  .button.basic.on-dark
    color rgb( 200 200 200 )
    border 1px solid rgba( 240 240 240 .1 )
    box-shadow 0 0 2px 1px rgba( 0 0 0 .2 ) inset
    text-shadow 0 -1px 2px black
    &:hover
      background rgba( 255 255 255 .1 )
      border-color rgba( 16 16 16 .5 )
      color white
      box-shadow 0 0 1px 1px rgba( 0 0 0 .5 ) inset, 0 0 16px 0px rgba( 0 0 0 .25 )
</style>
